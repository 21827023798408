import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FinanceSearch from '../SearchComponent/FinanceSearch';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
const userTypes = [
  { value: 'AM', label: 'Admin' },
  { value: 'EP', label: 'Employee' },
  { value: 'VS', label: 'Visitor' },
  { value: 'VP', label: 'Vip' },
  { value: 'AR', label: 'Artist' },
  { value: 'IN', label: 'Internal' },
  { value: 'VENDOR', label: 'Vendor' },
  { value: 'MM', label: 'Member' },
  { value: 'CA', label: 'Coach' }
];

const AdminUser = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [userDetails, setUserDetails] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    whatsapp_number: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    height: '',
    weight: '',
    user_type: '',
    job_title: '',
    internal_user: ''
  });

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  useEffect(() => {
    fetchUsers(page);
  }, [page]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const fetchUsers = async (page) => {
    try {
      const response = await axios.get(`${backendUrl}/api/users/users/?page=${page}`);
      console.log("Full API Response:", response.data); // Log the full response
  
      // Check if the response is an array
      if (Array.isArray(response.data)) {
        setUsers(response.data);
        console.log("Users:", response.data);
        setFilteredData(response.data);
        console.log("Filtered Data:", response.data);
        setTotalPages(Math.ceil(response.data.length / 10)); // Adjust if necessary
        setPaginationMeta({
          count: response.data.length,
          next: null,
          previous: null,
        });
      } else {
        console.log("Unexpected response structure:", response.data);
      }
    } catch (err) {
      console.log("Error fetching users:", err);
    }
  };

  
  useEffect(() => {
    console.log("Users state:", users);
    console.log("Filtered Data state:", filteredData);
  }, [users, filteredData]);
  
  
  
  const handlePreviousPage = () => {
    if (paginationMeta.previous) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (paginationMeta.next) {
      setPage(page + 1);
    }
  };

  const buttonStyle = {
    margin: '8px',
    padding: '8px 16px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    cursor: 'pointer',
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#b0bec5',
    cursor: 'not-allowed',
  };


  const fetchUserDetails = async (userId) => {
    try {
      const response = await axios.get(`${backendUrl}/api/users/get/${userId}/`);
      setUserDetails(response.data);
      setOpenDetailsDialog(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenDetailsDialog = (user) => {
    setSelectedUser(user);
    fetchUserDetails(user.id);
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };

  const handleOpenEditDialog = (user) => {
    setSelectedUser(user);
    setUserDetails({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone_number: user.phone_number,
      whatsapp_number: user.whatsapp_number,
      address1: user.address1,
      address2: user.address2,
      address3: user.address3,
      city: user.city,
      state: user.state,
      height: user.height,
      weight: user.weight,
      user_type: user.user_type,
      job_title: user.job_title,
      internal_user: user.internal_user
    });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveChanges = async () => {
    try {
      await axios.put(`${backendUrl}/api/users/update/${selectedUser.id}/`, userDetails);
      fetchUsers();
      alert('User Data Updated successfully!');
      handleCloseEditDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  useEffect(() => {
    fetchUsers();
  }, []);


  useEffect(() => {
    console.log("Filtered Data has changed:", filteredData);
  }, [filteredData]);
  
  const handleSearch = (searchParams) => {
    const filtered = users.filter(user => {
      return (
        (!searchParams.firstName || (user.first_name && user.first_name.toLowerCase().includes(searchParams.firstName.toLowerCase()))) &&
        (!searchParams.lastName || (user.last_name && user.last_name.toLowerCase().includes(searchParams.lastName.toLowerCase()))) &&
        (!searchParams.email || (user.email && user.email.toLowerCase().includes(searchParams.email.toLowerCase()))) &&
        (!searchParams.phoneNumber || (user.phone_number && user.phone_number.toLowerCase().includes(searchParams.phoneNumber.toLowerCase()))) &&
        (!searchParams.whatsappNumber || (user.whatsapp_number && user.whatsapp_number.toLowerCase().includes(searchParams.whatsappNumber.toLowerCase()))) &&
        // Add similar null checks for other properties
        true
      );
    });
    console.log(filteredData);
    setFilteredData(filtered);
  };
  
  
  
  return (
    <div>
      <h2>Users List</h2>
      <FinanceSearch onSearch={handleSearch} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <Button variant="contained" color="primary" onClick={() => {
    window.location.href = '/UserCreate';
  }}>Add User</Button>
        </Box>

        
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bolder' }}>ID</TableCell>
            <TableCell style={{ fontWeight: 'bolder' }}>Name</TableCell>
            <TableCell style={{ fontWeight: 'bolder' }}>Email</TableCell>
            <TableCell style={{ fontWeight: 'bolder' }}>Phone Number</TableCell>
            <TableCell sx={{ fontWeight: 'bolder', textAlign: 'right'}}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
  {filteredData.length > 0 ? (
    filteredData.map(user => (
      <TableRow key={user.id}>
        <TableCell>{user.id}</TableCell>
        <TableCell>{user.first_name}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.phone_number}</TableCell>
        <TableCell sx={{ fontWeight: 'bold', textAlign: 'right', padding: '12px' }}>
          <Button variant="contained" color="primary" onClick={() => handleOpenDetailsDialog(user)} sx={{ marginRight: '8px' }}>
            Details
          </Button>
          <Button variant="contained" color="primary" onClick={() => handleOpenEditDialog(user)}>
            Edit
          </Button>
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={5} align="center">No users found</TableCell>
    </TableRow>
  )}
</TableBody>



      </Table>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button 
                    onClick={handlePreviousPage}
                    style={page <= 1 ? disabledButtonStyle : buttonStyle}
                    disabled={page <= 1}
                >
                    Previous
                </Button>
                <Button 
                    onClick={handleNextPage}
                    style={page >= totalPages ? disabledButtonStyle : buttonStyle}
                    
                >
                    Next
                </Button>
            </div>

      <Dialog open={openDetailsDialog} onClose={handleCloseDetailsDialog}
      maxWidth={false}
      PaperProps={{
        style: {
          width: '80vw', // Adjust the width as needed
          height: '90vh', // Adjust the height as needed
          margin: 'auto',
        },
      }}>
        <DialogTitle>User Details</DialogTitle>
        <DialogContent>
          {userDetails && (
            <>
               <Typography variant="body1" gutterBottom><strong>Name:</strong> {userDetails.first_name}</Typography>
            <Typography variant="body1" gutterBottom><strong>Last Name:</strong> {userDetails.last_name}</Typography>
            <Typography variant="body1" gutterBottom><strong>Email:</strong> {userDetails.email}</Typography>
            <Typography variant="body1" gutterBottom><strong>Phone Number:</strong> {userDetails.phone_number}</Typography>
            <Typography variant="body1" gutterBottom><strong>WhatsApp Number:</strong> {userDetails.whatsapp_number}</Typography>
            <Typography variant="body1" gutterBottom><strong>Address 1:</strong> {userDetails.address1}</Typography>
            <Typography variant="body1" gutterBottom><strong>Address 2:</strong> {userDetails.address2}</Typography>
            <Typography variant="body1" gutterBottom><strong>Address 3:</strong> {userDetails.address3}</Typography>
            <Typography variant="body1" gutterBottom><strong>City:</strong> {userDetails.city}</Typography>
            <Typography variant="body1" gutterBottom><strong>State:</strong> {userDetails.state}</Typography>
            <Typography variant="body1" gutterBottom><strong>Height:</strong> {userDetails.height}</Typography>
            <Typography variant="body1" gutterBottom><strong>Weight:</strong> {userDetails.weight}</Typography>
            <Typography variant="body1" gutterBottom><strong>User Type:</strong> {userTypes.find(type => type.value === userDetails.user_type)?.label}</Typography>
            <Typography variant="body1" gutterBottom><strong>Job Title:</strong> {userDetails.job_title}</Typography>
            <Typography variant="body1" gutterBottom><strong>Internal User:</strong> {userDetails.internal_user ? 'Yes' : 'No'}</Typography>

            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetailsDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="First Name"
            name="first_name"
            value={userDetails.first_name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Last Name"
            name="last_name"
            value={userDetails.last_name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={userDetails.email}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Phone Number"
            name="phone_number"
            value={userDetails.phone_number}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="WhatsApp Number"
            name="whatsapp_number"
            value={userDetails.whatsapp_number}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Address 1"
            name="address1"
            value={userDetails.address1}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Address 2"
            name="address2"
            value={userDetails.address2}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Address 3"
            name="address3"
            value={userDetails.address3}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="City"
            name="city"
            value={userDetails.city}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="State"
            name="state"
            value={userDetails.state}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Height"
            name="height"
            value={userDetails.height}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Weight"
            name="weight"
            value={userDetails.weight}
            onChange={handleChange}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>User Type</InputLabel>
            <Select
              name="user_type"
              value={userDetails.user_type}
              onChange={handleChange}
            >
              {userTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Job Title"
            name="job_title"
            value={userDetails.job_title}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Internal User"
            name="internal_user"
            value={userDetails.internal_user}
            onChange={handleChange}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">Cancel</Button>
          <Button onClick={handleSaveChanges} color="primary">Save Changes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminUser;
